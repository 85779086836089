.block.banner {
  width: 100%;
  height: 300px;

  img:not(.placeholder) {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .text {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 300px;
    padding: 50px 0 50px 50px;
    background: linear-gradient(90deg, #000 0%, rgba(0, 0, 0, 0) 100%);

    p {
      width: 100%;
      margin-bottom: 0;
      color: #fff;
      font-size: 33px;
      font-style: normal;
      font-weight: 300;
      line-height: 42px;
    }
  }
}

.block-editor-banner .block.banner .ui.message {
  height: 300px;
}
