@import 'variables';

/* import ClassicUI based Theming custom styles */
@import url('https://kwk.dev.acsr.de/++api++/@@custom.css');

// revoke hiding changeNote field from edit & add forms
.field-wrapper-changeNote {
  display: block;
}

// fix missing container width in sitemap
#page-sitemap > * {
  @include default-container-width();
}

// reduce vertical whitespace of Button
.__button {
  .button.container {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

// override _header.scss

.header-wrapper {
  // reduce vertical whitespace of solo H2
  h2,
  h2:last-child {
    margin-top: 60px;
    margin-bottom: 40px;
  }
  .header {
    // unset justify-content: space-between
    .tools-wrapper {
      justify-content: unset;
    }
    .logo-nav-wrapper {
      // increase logo size
      .logo {
        max-width: 260px;
        max-height: 80px;
        a img {
          max-width: 170%;
          @media only screen and (max-width: $largest-mobile-screen) {
            max-width: 100%;
          }
        }
      }
    }
  }
}

// reduce padding in dropdown navigation on desktop
.navigation {
  ul.desktop-menu {
    .submenu {
      padding: 20px 102px 30px;
      ul {
        padding: 20px 0 0;
      }
    }
  }
}

// Set volto-slate default table styles
table.slate-table {
  min-width: 680px;
  overflow-x: auto;
  white-space: nowrap;
}

table.slate-table td,
table.slate-table th {
  padding: 0.5rem;
  padding: 10px;
  border: 0.25rem solid #fff;
  background-color: #eee;
  vertical-align: middle;
}

table.slate-table th {
  border-bottom: none;
  background-color: #ccc;
}

table.slate-table th p {
  color: #fff;
  font-size: 0.9em;
  font-weight: bold;
  letter-spacing: 0.03em;
}

table.slate-table td p {
  font-size: 0.8em;
}

table.slate-table th p {
  font-size: 0.9em;
}

// Slider Overlay
.slider {
  .ui.button {
    border-radius: 3px;
    background-color: transparent;
  }
  .teaser-item {
    .teaser-item-title {
      background: rgba(0, 0, 0, 0.4);
    }

    .teaser-item-title.has--slider--flagAlign--left {
      border-bottom-right-radius: 3px;
      border-top-right-radius: 3px;
    }

    .teaser-item-title.has--slider--flagAlign--right {
      border-bottom-left-radius: 3px;
      border-top-left-radius: 3px;
    }
  }
}
